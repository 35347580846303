.App {
  position: relative;
  text-align: center;
  background-color: #0075FF;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  width: 80%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FFFFFF;
}

.header-logo {
  position: absolute;
  height: 23.999998092651367px;
  width: 120px;
  left: 20px;
  top: 48px;
  border-radius: 0px;
}

.top-text{
 font-size: 25px;
}

